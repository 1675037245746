import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ViewContent from "../../../components/ViewContent";

import {
  Alert,
  Stack,
  Link,
  Button,
  Box,
  LinearProgress,
  CircularProgress,
  Typography,
  TextField,
  Card,
  CardContent,
  Divider,
  Backdrop,
  IconButton,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { fetchData } from "../../../fetchData";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useModal } from "../../../contexts/ModalContext";

import NpsEditPreviewDrawer from "./drawer/preview";
import AddNpsQuestion from "./modal/addQuestion";

const EditBox = ({
  state,
  title,
  text,
  icon,
  button,
  children,
  divider = true,
}: {
  state: boolean;
  title: string;
  text?: any;
  icon: any;
  button: any;
  divider?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2} flex={1}>
          {icon}
          <Box flex={1}>
            <Typography variant="h6" fontWeight={state ? 500 : 400}>
              {title}
            </Typography>
            {text && (
              <Typography
                variant="body2"
                color={state ? "text.primary" : "text.disabled"}
              >
                {text}
              </Typography>
            )}
            {children}
          </Box>
        </Stack>
        {button}
      </Stack>
      {divider && <Divider sx={{ my: 2 }} />}
    </>
  );
};

const NpsEdit = () => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(true);
  const [sending, setSending] = useState(false);
  const [drawer, setDrawer] = useState(true);
  const [count, setCount] = useState(0);
  const drawerWidth = 450;
  const [title, setTitle] = useState({
    value: "",
    error: false,
    progress: false,
    editing: false,
  });
  const [error, setError] = useState(false);
  const [nps, setNps] = useState<any>({
    questions: [],
  });

  const init = async () => {
    try {
      const users = await fetchData(`/users?is_admin=false&status=true`, {
        method: "GET",
      });

      setCount(users.count);

      const nps = await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "GET",
      });

      setNps(nps);
      setTitle({ ...title, value: nps.title });
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setProgress(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataSave = async (data: any) => {
    try {
      await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });

      setNps(data);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleTitleSubmit = async () => {
    const data = { ...nps, title: title.value };
    setTitle({ ...title, progress: true });

    if (await handleDataSave(data)) {
      setTitle({ ...title, editing: false, progress: false });
      snackbar?.open("Título atualizado com sucesso!", "success");
    } else {
      setTitle({ ...title, progress: false });
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }
  };

  const handleRemoveQuestion = async (id: number) => {
    if (
      await handleDataSave({
        ...nps,
        questions: nps.questions.filter(
          (question: any, index: number) => index != id,
        ),
      })
    ) {
      snackbar?.open("Questão excluída com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a exclusão.",
        "error",
      );
    }
  };

  const handleSendNps = async () => {
    setSending(true);

    try {
      await fetchData(`/surveys/nps/${params.npsID}`, {
        method: "PATCH",
      });

      snackbar?.open("Pesquisa enviada com sucesso!", "success");
      navigate(`/nps/${params.npsID}?q=success`);
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível enviar a pesquisa NPS.",
        "error",
      );
    }

    setSending(false);
  };

  return (
    <>
      {sending && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={sending}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box width={drawer ? `calc(100% - ${drawerWidth}px)` : "100%"}>
        <ViewContent
          title="NPS"
          label="Rascunho"
          icon={<DonutSmallIcon sx={{ mr: 2 }} />}
          button={
            !loading && (
              <>
                <Button
                  variant="text"
                  size="small"
                  color="white"
                  onClick={() => {
                    navigate("/nps");
                  }}
                >
                  Terminar mais tarde
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="quaternary"
                  disabled={nps.questions.length === 0}
                  onClick={() => {
                    handleSendNps();
                  }}
                >
                  Enviar
                </Button>
              </>
            )
          }
        >
          <div>
            {loading && <LinearProgress sx={{ mt: 1 }} />}
            {!loading && (
              <>
                <Box maxWidth={500}>
                  {!title.editing && (
                    <>
                      <Typography variant="h4">{nps?.title}</Typography>
                      <Link
                        component="button"
                        sx={{ p: 0 }}
                        onClick={() => {
                          setTitle({ ...title, editing: true });
                        }}
                      >
                        Editar nome
                      </Link>
                    </>
                  )}

                  {title.editing && (
                    <Stack direction="column" spacing={1}>
                      <TextField
                        size="small"
                        fullWidth
                        value={title.value}
                        name="title"
                        placeholder="Dê um nome à pesquisa"
                        error={title.error}
                        onChange={(e: any) => {
                          setTitle({
                            ...title,
                            value: e.target.value,
                            error: !e.target.value,
                          });
                        }}
                      />
                      {title.error && (
                        <Alert severity="error">
                          Digite um nome para esta pesquisa
                        </Alert>
                      )}
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setTitle({ ...title, editing: false });
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={handleTitleSubmit}
                        >
                          {!title.progress && "Salvar"}
                          {title.progress && (
                            <CircularProgress
                              size={12}
                              sx={{ color: "#FFF" }}
                            />
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  )}
                </Box>
                <Card sx={{ mt: 2 }}>
                  <CardContent>
                    <EditBox
                      state={true}
                      title="Para"
                      icon={<CheckCircleIcon color="success" />}
                      button={
                        <Button variant="outlined" size="small" disabled>
                          Editar segmentação
                        </Button>
                      }
                      text={
                        <>
                          <b>Todos os contatos</b> da base <b>Atlântico</b>.{" "}
                          {progress && <CircularProgress size={15} />}
                          {!progress && (
                            <Typography
                              variant="body2"
                              color="primary"
                              component="span"
                              fontWeight="bold"
                            >
                              {count} destinatários.
                            </Typography>
                          )}
                        </>
                      }
                    />

                    <EditBox
                      state={true}
                      title="Meio de comunicação"
                      icon={<CheckCircleIcon color="success" />}
                      button={
                        <Button size="small" variant="outlined" disabled>
                          Editar veiculação
                        </Button>
                      }
                      text="E-mail"
                    />

                    <EditBox
                      state={false}
                      title="Conteúdo"
                      icon={
                        <CheckCircleIcon
                          color={
                            nps.questions.length > 0 ? "success" : "disabled"
                          }
                        />
                      }
                      button={
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            modal?.open(
                              "Nova Questão",
                              <AddNpsQuestion
                                values={nps}
                                handleDataSave={handleDataSave}
                              />,
                            );
                          }}
                        >
                          Criar questão
                        </Button>
                      }
                      text={
                        nps.questions?.length === 0
                          ? "Formule uma pergunta para medir a satisfação e a lealdade dos clientes."
                          : null
                      }
                    >
                      {nps.questions.length > 0 && (
                        <Stack>
                          {nps.questions.map(
                            (question: string, index: number) => (
                              <Box key={`question-${index}`}>
                                <Stack direction="row" alignItems="center">
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    flexGrow={1}
                                    borderLeft={2}
                                    borderColor="primary.main"
                                    paddingLeft={2}
                                  >
                                    {question}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      handleRemoveQuestion(index);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Stack>
                                <Divider sx={{ my: 1 }} />
                              </Box>
                            ),
                          )}
                        </Stack>
                      )}
                    </EditBox>

                    <EditBox
                      divider={false}
                      state={false}
                      title="Pré-visualização"
                      icon={
                        <CheckCircleIcon
                          color={drawer ? "success" : "disabled"}
                        />
                      }
                      button={
                        <NpsEditPreviewDrawer
                          state={drawer}
                          setState={setDrawer}
                          values={nps}
                          width={drawerWidth}
                        />
                      }
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </div>
        </ViewContent>
      </Box>
    </>
  );
};

export default NpsEdit;
