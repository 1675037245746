import { useEffect, useState } from "react";
import {
  Chip,
  Unstable_Grid2 as Grid,
  Box,
  Stack,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import ChurchIcon from "@mui/icons-material/Church";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import { useAuth } from "../../../contexts/AuthContext";
import { fetchData } from "../../../fetchData";

import RRDeliveredTasks from "./drawers/rrDelivered";
import RRTaskLog from "./drawers/rrTaskLog";
import RRTaskNotify from "./drawers/rrTaskNotify";
import Organization from "../Organization";

const NpsProgressBox = (props: any) => {
  const { score, color, radius } = props;

  return (
    <Box
      sx={{
        p: "3px 0",
        width: `${score}%`,
        backgroundColor: color,
        textAlign: "center",
        color: "white",
        borderRadius: radius || "none",
      }}
    >
      <Typography variant="caption" fontWeight="bold">
        {score}%
      </Typography>
    </Box>
  );
};

const NumCard = ({
  title,
  number,
  percent,
  action = null,
  loading,
}: {
  title: string;
  number: number;
  percent: number;
  action?: any;
  loading: boolean;
}) => {
  return (
    <Card sx={{ flex: 1, height: "100%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Stack direction="row" spacing={1} sx={{ height: "100%" }}>
          <Box
            sx={{
              width: 5,
              height: `${percent}%`,
              borderRadius: 5,
              backgroundColor: "primary.main",
            }}
          ></Box>
          <Stack flex={1} justifyContent="space-between">
            {loading && <CircularProgress size={32} />}
            {!loading && (
              <div>
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {title}
                </Typography>
                <Typography variant="h4" component="div">
                  {number}
                </Typography>
              </div>
            )}
            {action && action}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const Dashboard = () => {
  const auth = useAuth();
  const [logID, setLogID] = useState<number | string>("");
  const [graph, setGraph] = useState({
    state: true,
    total: 0,
    empresas: { total: 0, percentual: 0 },
    igrejas: { total: 0, percentual: 0 },
    save: { total: 0, percentual: 0 },
    terceiroSetor: { total: 0, percentual: 0 },
  });
  const [photo, setPhoto] = useState({
    state: false,
    url: "",
  });

  const initPhoto = async () => {
    const response = await fetch(
      `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=landscapes+colorful&image_type=photo&pretty=true&per_page=100&orientation=horizontal`,
    );

    if (response.ok) {
      response.json().then((result) => {
        setPhoto({
          ...photo,
          url: result.hits[Math.floor(Math.random() * 100)].largeImageURL,
        });
      });
    }
  };

  const init = async () => {
    const result = await fetchData(`/organizations?limit=1000&status=true`, {
      method: "GET",
    });

    if (result) {
      let empresas = 0,
        igrejas = 0,
        save = 0,
        terceiroSetor = 0;

      result.rows.map((organization: any) => {
        if (organization.profile && organization.profile.includes("Empresas"))
          empresas++;
        if (organization.profile && organization.profile.includes("Igrejas"))
          igrejas++;
        if (organization.profile && organization.profile.includes("Save"))
          save++;
        if (
          organization.profile &&
          organization.profile.includes("Terceiro Setor")
        )
          terceiroSetor++;
      });

      setGraph({
        state: false,
        total: result.count,
        empresas: {
          total: empresas,
          percentual: Math.floor((empresas / result.count) * 100),
        },
        igrejas: {
          total: igrejas,
          percentual: Math.floor((igrejas / result.count) * 100),
        },
        save: {
          total: save,
          percentual: Math.floor((save / result.count) * 100),
        },
        terceiroSetor: {
          total: terceiroSetor,
          percentual: Math.floor((terceiroSetor / result.count) * 100),
        },
      });
    }
  };

  useEffect(() => {
    initPhoto();
    init();
  }, []);

  const onLoad = () => {
    setPhoto({ ...photo, state: true });
  };

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      spacing={2}
      sx={{ height: "calc(100% - 72px)" }}
    >
      <Box sx={{ height: "350px" }}>
        <Card sx={{ height: "100%" }}>
          <CardContent
            sx={{
              p: 0,
              paddingBottom: "0 !important",
              height: "100%",
              position: "relative",
            }}
          >
            {photo.state && (
              <Stack
                sx={{
                  p: 3,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  background:
                    "linear-gradient(180deg, rgba(0,0,0,0) 42%, rgba(0,0,0,0.8) 100%)",
                }}
                direction="column"
                justifyContent="flex-end"
                spacing={2}
              >
                <Typography variant="h3" color="white" fontWeight={300}>
                  Olá, {auth?.user.nickname}.
                </Typography>
              </Stack>
            )}
            <img
              src={photo.url}
              onLoad={onLoad}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: !photo.state ? "none" : "flex",
              }}
            />
            {!photo.state && (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid xs={12} lg={4} sx={{ height: "100%" }}>
            <Card sx={{ height: "100%" }}>
              <CardContent sx={{ height: "100%" }}>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Chip label="Score NPS" variant="outlined" />
                    <Typography variant="body2">2023</Typography>
                  </Stack>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: 150,
                      height: 150,
                      borderRadius: 150,
                      borderWidth: 7,
                      borderStyle: "solid",
                      borderColor: "primary.main",
                    }}
                  >
                    <Typography
                      variant="h2"
                      fontWeight={500}
                      color="primary.main"
                    >
                      46
                    </Typography>
                  </Stack>
                  <Stack direction="row" sx={{ width: "100%" }}>
                    <NpsProgressBox
                      score={40}
                      color="#C00514"
                      radius="5px 0 0 5px"
                    />
                    <NpsProgressBox score={35} color="#FEC230" />
                    <NpsProgressBox
                      score={25}
                      color="#6EAA2E"
                      radius="0 5px 5px 0"
                    />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} lg={8}>
            <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
              <Card>
                <CardContent sx={{ pb: "16px !important" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Chip
                      label="Tarefas"
                      variant="outlined"
                      color="secondary"
                    />
                    <Box sx={{ flex: 1 }}>
                      <Stack direction="row" spacing={2}>
                        <RRTaskNotify />
                        <RRTaskLog taskLog={{ set: setLogID, get: logID }} />
                        <RRDeliveredTasks
                          taskLog={{ set: setLogID, get: logID }}
                        />
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>

              <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
                <NumCard
                  title="Clientes"
                  loading={graph.state}
                  number={graph.total}
                  percent={100}
                  action={
                    <Button size="small" variant="outlined">
                      Ver todos
                    </Button>
                  }
                />
                <NumCard
                  title="Empresas"
                  loading={graph.state}
                  number={graph.empresas.total}
                  percent={graph.empresas.percentual}
                  action={<BusinessIcon fontSize="large" color="primary" />}
                />
                <NumCard
                  title="Igrejas"
                  loading={graph.state}
                  number={graph.igrejas.total}
                  percent={graph.igrejas.percentual}
                  action={<ChurchIcon fontSize="large" color="primary" />}
                />
                <NumCard
                  title="Save"
                  loading={graph.state}
                  number={graph.save.total}
                  percent={graph.save.percentual}
                  action={
                    <MonetizationOnIcon fontSize="large" color="primary" />
                  }
                />
                <NumCard
                  title="Terceiro Setor"
                  loading={graph.state}
                  number={graph.terceiroSetor.total}
                  percent={graph.terceiroSetor.percentual}
                  action={
                    <AccountBalanceIcon fontSize="large" color="primary" />
                  }
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Dashboard;
