import { useMemo, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { fetchData } from "../fetchData";
import jwt_decode from "jwt-decode";

const parseJwt = (token: any) => {
  try {
    return jwt_decode(token);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState("");

  const login = async (data: any) => {
    try {
      const result = await fetchData("/auth", {
        method: "POST",
        body: JSON.stringify({
          username: data.login,
          password: data.password,
        }),
      });

      if (result) {
        localStorage.setItem(
          `${process.env.REACT_APP_KEYNAME}token`,
          result.access_token,
        );
        tokenVerify();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem(`${process.env.REACT_APP_KEYNAME}token`);
    setUser("");
  };

  const tokenVerify = () => {
    try {
      const decodedJWT: any = parseJwt(
        localStorage.getItem(`${process.env.REACT_APP_KEYNAME}token`),
      );

      if (decodedJWT.exp * 1000 < Date.now()) {
        logout();
        return false;
      }

      setUser(decodedJWT);
      return true;
    } catch (error) {
      logout();
      return false;
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      tokenVerify,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
