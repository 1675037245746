import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Unstable_Grid2 as Grid,
  TextField,
  Alert,
  MenuItem,
  Button,
  Stack,
  Divider,
  CircularProgress,
} from "@mui/material";

import SelectChipField from "../../../../components/SelectChipField";

import EditIcon from "@mui/icons-material/Edit";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useOrganization } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";
import {
  organizationsRegimeTributarioLabels,
  organizationsAreasDeAtuacaoLabels,
} from "../../../../utils/labels";

type FormValues = {
  isncricao_estadual: number;
  isncricao_municipal: number;
  login: string;
  password: string;
  regime: string;
  area: Array<string>;
};

const OrganizationFiscalTab = () => {
  const auth = useAuth();
  const snackbar = useSnackbar();
  const { data: organization, set } = useOrganization();
  const [progress, setProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      isncricao_estadual: organization.fiscal.isncricao_estadual || "",
      isncricao_municipal: organization.fiscal.isncricao_municipal || "",
      login: organization.fiscal.login || "",
      password: organization.fiscal.password || "",
      regime: organization.fiscal.regime || "",
      area: organization.fiscal.area || [],
    },
  });

  async function onSubmit(data: any) {
    setProgress(true);
    const payload = { ...organization, fiscal: data };

    try {
      await fetchData(`/organizations/${organization._id}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      set(payload);

      setIsEditing(false);
      snackbar?.open("Informações fiscais atualizadas com sucesso!", "success");
    } catch (error) {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a ação.",
        "error",
      );
    }

    setProgress(false);
  }

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <SelectChipField
              size="small"
              name="area"
              disabled={!isEditing}
              register={register}
              get={getValues}
              required="Informe a área de atuação da organização."
              label="Área de Atuação"
              error={errors?.area ? true : false}
            >
              {organizationsAreasDeAtuacaoLabels.map((label: string) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </SelectChipField>
            {errors?.area && (
              <Alert severity="error">{errors.area.message?.toString()}</Alert>
            )}
          </Grid>
          <Grid xs={12} lg={6}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Regime Tributário"
                  size="small"
                  margin="dense"
                  disabled={!isEditing}
                  fullWidth
                  variant="outlined"
                >
                  {organizationsRegimeTributarioLabels.map((label: string) => (
                    <MenuItem key={label} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="regime"
              rules={{ required: "Selecione o regime tributário." }}
            />
            {errors?.regime && (
              <Alert severity="error">
                {errors.regime.message?.toString()}
              </Alert>
            )}
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              {...register("isncricao_estadual")}
              label="Inscrição Estadual"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("isncricao_municipal")}
              label="Inscrição Municipal"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("login")}
              label="Login Municipal"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              {...register("password")}
              label="Senha Municipal"
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              margin="dense"
              size="small"
            />
          </Grid>
        </Grid>

        {auth?.user.permission_level === "manager" && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              justifyContent={isEditing ? "flex-end" : "flex-start"}
            >
              {!isEditing && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
                </Button>
              )}
              {isEditing && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {!progress && "Salvar"}
                    {progress && (
                      <CircularProgress size={12} sx={{ color: "#FFF" }} />
                    )}
                  </Button>
                </>
              )}
            </Stack>
          </>
        )}
      </form>
    </Box>
  );
};

export default OrganizationFiscalTab;
