import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Alert,
  Button,
  Chip,
  Box,
  CircularProgress,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { NpsIcon } from "../../../utils/labels";

interface Props {
  label: string;
  icon: any;
  color: string;
  data: Array<object>;
}

const columns: GridColDef[] = [
  {
    field: "user",
    headerName: "Contato",
    flex: 1,
    editable: true,
    renderCell: (params: any) => {
      return (
        <Stack
          direction="column"
          alignContent="flex-start"
          width="100%"
          spacing={1}
        >
          <Typography variant="body2">{params.row.user.name}</Typography>
          <Chip
            label={params.row.client.reference}
            size="small"
            variant="outlined"
          />
        </Stack>
      );
    },
  },
];

const NpsScoreDrawer = ({ label, icon, color, data }: Props) => {
  const [state, setState] = useState(false);

  return (
    <>
      <Chip
        label={label}
        icon={icon}
        variant="outlined"
        sx={{ borderColor: color }}
        onClick={() => {
          setState(true);
        }}
      />
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 300 }} width={300} height="100%">
          <Stack height="100%">
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                {icon}
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  {label}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <DataGrid
              rows={data}
              rowHeight={75}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              sx={{ flex: 1 }}
            />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default NpsScoreDrawer;
