import Admin from "../layouts/Admin/Admin";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "../views/Admin/Dashboard";
import Persons from "../views/Admin/Persons";
import Person from "../views/Admin/Person";

import Organizations from "../views/Admin/Organizations";
import Organization from "../views/Admin/Organization";

import Users from "../views/Admin/Users";
import Csat from "../views/Admin/Csat";
import Nps from "../views/Admin/NpsList";
import NpsScore from "../views/Admin/NpsScore";
import NpsEdit from "../views/Admin/NpsEdit";
import Messaging from "../views/Admin/Messaging";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Admin />}>
          <Route index element={<Dashboard />} />
          <Route path="persons">
            <Route index element={<Persons />} />
            <Route
              path=":personID"
              element={<Person />}
              key={Math.random() * 1000}
            />
          </Route>
          <Route path="organizations">
            <Route index element={<Organizations />} />
            <Route path=":organizationID" element={<Organization />} />
          </Route>
          <Route path="users">
            <Route index element={<Users />} />
          </Route>
          <Route path="csat">
            <Route index element={<Csat />} />
          </Route>
          <Route path="nps">
            <Route index element={<Nps />} />
            <Route path=":npsID" element={<NpsScore />} />
            <Route path="edit/:npsID" element={<NpsEdit />} />
          </Route>
          <Route path="messaging">
            <Route index element={<Messaging />} />
          </Route>
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
