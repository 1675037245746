import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { fetchData } from "../../../../fetchData";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";

import { npsOptions } from "../../../../utils/labels";

type FormValues = {
  title: string;
  option: string;
};

const AddNps = () => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      option: "E-mail",
    },
  });

  const showError = () => {
    snackbar?.open(
      "Ops! No momento não foi possível criar a pesquisa NPS.",
      "error",
    );
  };

  async function onSubmit(data: any) {
    setProgress(true);

    try {
      const nps = await fetchData(`/surveys/nps`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      snackbar?.open("Pesquisa NPS criada com sucesso!", "success");

      navigate(`/nps/edit/${nps._id}`);
      modal?.close();
    } catch (error) {
      showError();
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("title", {
          required: "Informe o título da pesquisa.",
        })}
        label="Título"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.title ? true : false}
      />
      {errors?.title && (
        <Alert severity="error">{errors.title.message?.toString()}</Alert>
      )}

      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Veiculação"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          >
            {npsOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name="option"
      />
      {errors?.option && (
        <Alert severity="error">{errors.option.message?.toString()}</Alert>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={modal?.close}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Criar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default AddNps;
