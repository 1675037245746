import { useState } from "react";
import { Button, TextField, Box, Stack } from "@mui/material";

import AddCommentIcon from "@mui/icons-material/AddComment";
import LockResetIcon from "@mui/icons-material/LockReset";
import MailIcon from "@mui/icons-material/Mail";

import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { usePerson } from "..";
import { useAuth } from "../../../../contexts/AuthContext";

import { fetchData } from "../../../../fetchData";

const PersonActionsTab = () => {
  const [isComment, setIsComment] = useState(false);
  const [note, setNote] = useState("");
  const snackbar = useSnackbar();
  const auth = useAuth();
  const person = usePerson();

  const handleSaveNote = async () => {
    try {
      await fetchData("/histories", {
        method: "POST",
        body: JSON.stringify({
          isComment: true,
          user: auth?.user._id,
          username: auth?.user.name,
          content: note,
          type: "user",
          type_id: person.data._id,
        }),
      });
      person.reload.set(new Date());
      snackbar?.open("Nota adicionada com sucesso!", "success");
      setNote("");
      setIsComment(false);
    } catch (error) {
      snackbar?.open(
        "Ops! Ocorreu um erro ao adicionar a nota. Tente mais tarde.",
        "error",
      );
    }
  };

  return (
    <>
      {!isComment && (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ m: 2 }}
        >
          <Button
            size="small"
            color="quaternary"
            variant="outlined"
            onClick={() => {
              setIsComment(true);
            }}
          >
            <AddCommentIcon sx={{ mr: 1 }} /> Nova anotação
          </Button>
          {/* <Button size="small" color="primary" variant="outlined">
            <LockResetIcon sx={{ mr: 1 }} /> Redefinir senha
          </Button>
          <Button size="small" color="tertiary" variant="outlined">
            <MailIcon sx={{ mr: 1 }} /> E-mail Bem-Vindo
          </Button> */}
        </Stack>
      )}
      {isComment && (
        <Box sx={{ p: 2, backgroundColor: "note.main" }}>
          <TextField
            id="standard-multiline-static"
            multiline
            fullWidth
            rows={4}
            variant="standard"
            color="quaternary"
            hiddenLabel
            autoFocus
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Box sx={{ textAlign: "right", pt: 2 }}>
            <Button
              size="small"
              sx={{ color: "text.secondary" }}
              onClick={() => {
                setIsComment(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              color="quaternary"
              type="submit"
              onClick={() => handleSaveNote()}
              disabled={note === "" ? true : false}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PersonActionsTab;
