import { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import BarChartIcon from "@mui/icons-material/BarChartOutlined";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import DomainIcon from "@mui/icons-material/Domain";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PersonIcon from "@mui/icons-material/Person";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";

import InboxIcon from "@mui/icons-material/Inbox";

import Logo from "../../assets/images/symbol.svg";

import { stringAvatar } from "../../utils/stringAvatar";

import { useAuth } from "../../contexts/AuthContext";
import { useModal } from "../../contexts/ModalContext";

import Snackbar from "../../components/Snackbar";
import Modal from "../../components/Modal";

import AddPerson from "../../views/Admin/Dashboard/modals/addPerson";
import AddOrganization from "../../views/Admin/Dashboard/modals/addOrganization";
import AddUser from "../../views/Admin/Dashboard/modals/addUser";
import AddNps from "../../views/Admin/Dashboard/modals/addNps";
import Search from "../../views/Admin/Dashboard/Search";
import MessagingDrawer from "../../views/Admin/Messaging/drawer";

import { useThemeMode } from "../../contexts/ThemeModeContext";

const drawerWidth = 70;

const firstNav = [
  {
    title: "Dashboard",
    icon: <CategoryOutlinedIcon />,
    link: "/",
  },
  {
    title: "Pessoas",
    icon: <GroupIcon />,
    link: "persons",
  },
  {
    title: "Organizações",
    icon: <DomainOutlinedIcon />,
    link: "organizations",
  },
  {
    title: "Usuários",
    icon: <AssignmentIndOutlinedIcon />,
    link: "users",
  },
];

const secondNav = [
  {
    title: "Pesquisa CSAT",
    icon: <InsertEmoticonIcon />,
    link: "csat",
  },
  {
    title: "Pesquisas NPS",
    icon: <DonutSmallOutlinedIcon />,
    link: "nps",
  },
  // {
  //   title: "Tarefas",
  //   icon: <AssignmentIcon />,
  //   path: "",
  // },
  {
    title: "Comunicados",
    icon: <CampaignOutlinedIcon />,
    link: "messaging",
  },
];

function Admin(props: any) {
  const { window } = props;
  const theme = useThemeMode();
  const auth = useAuth();
  const modal = useModal();
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = useState<null>(null);
  const [anchorElOpts, setAnchorElOpts] = useState<null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    auth?.tokenVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenOptsMenu = (event: any) => {
    setAnchorElOpts(event.currentTarget);
  };

  const handleCloseOptsMenu = () => {
    setAnchorElOpts(null);
  };

  const handleAddItem = (schema: string) => {
    switch (schema) {
      case "organization":
        modal?.open("Criar Organização", <AddOrganization />);
        break;
      case "user":
        modal?.open("Criar Usuário", <AddUser />);
        break;
      case "nps":
        modal?.open("Criar Pesquisa NPS", <AddNps />);
        break;

      default:
        modal?.open("Criar Pessoa", <AddPerson />);
        break;
    }

    handleCloseOptsMenu();
  };

  // const toggleTheme = () => {
  // };

  const toolbar = (
    <>
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flexGrow: 0 }}>
        <Search />
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          paddingLeft: "2em",
        }}
      >
        <Tooltip title="Caixa de entrada">
          <IconButton>
            {/* <Badge color="quaternary" badgeContent={1}> */}
            <Badge color="quaternary">
              <InboxIcon sx={{ color: "#CCC" }} />
            </Badge>
          </IconButton>
        </Tooltip>
        {auth?.user.permission_level === "manager" && (
          <Tooltip title="Adição rápida">
            <IconButton
              onClick={(e) => {
                handleOpenOptsMenu(e);
              }}
              sx={{ marginLeft: ".2em" }}
            >
              <AddCircleOutlineOutlinedIcon sx={{ color: "text.primary" }} />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElOpts}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorElOpts)}
          onClose={handleCloseOptsMenu}
        >
          <MenuItem
            onClick={() => {
              handleAddItem("person");
            }}
          >
            <PersonIcon sx={{ color: "text.primary", marginRight: ".5em" }} />
            <Typography textAlign="center">Pessoa</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAddItem("organization");
            }}
          >
            <DomainIcon sx={{ color: "text.primary", marginRight: ".5em" }} />
            <Typography textAlign="center">Organização</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleAddItem("user");
            }}
          >
            <AssignmentIndIcon
              sx={{ color: "text.primary", marginRight: ".5em" }}
            />
            <Typography textAlign="center">Usuário</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAddItem("nps");
            }}
          >
            <DonutSmallIcon sx={{ marginRight: ".5em" }} />
            <Typography textAlign="center">Pesquisa NPS</Typography>
          </MenuItem>
          <MessagingDrawer
            title="Novo Comunicado"
            onOpen={handleCloseOptsMenu}
          />
        </Menu>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          paddingLeft: "1em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            paddingLeft: "1em",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ color: "text.primary" }}
          >
            Olá, {auth?.user.nickname}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0, paddingLeft: "1em" }}>
          <Tooltip title={auth?.user.name}>
            <IconButton
              onClick={(e) => {
                handleOpenUserMenu(e);
              }}
              sx={{ p: 0 }}
            >
              {/* <Avatar alt="John Doe" src="/static/images/avatar/2.jpg" /> */}
              <Avatar {...stringAvatar(auth?.user.name)} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* <MenuItem onClick={handleCloseUserMenu}>
              <SettingsIcon
                sx={{ color: "text.primary", marginRight: ".5em" }}
              />
              <Typography textAlign="center">Preferências</Typography>
            </MenuItem>
            <Divider /> */}
            <MenuItem onClick={auth?.logout}>
              <LogoutOutlinedIcon
                sx={{ color: "text.primary", marginRight: ".5em" }}
              />
              <Typography textAlign="center">Sair</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );

  const drawer = (
    <div style={{ padding: "0 15px" }}>
      <Toolbar style={{ padding: 0 }}>
        <img src={Logo} width="30" alt={process.env.REACT_APP_NAME} />
      </Toolbar>
      <Divider />
      <List>
        {firstNav.map((item, index) => (
          <Tooltip key={`fnav${index}`} title={item.title} placement="right">
            <Link to={item.link}>
              <ListItem disableGutters>
                <ListItemButton sx={{ padding: "5px 0 5px 7px" }}>
                  <ListItemIcon
                    sx={{ margin: "0 auto", color: "text.primary" }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </List>
      <Divider />
      <List>
        {secondNav.map((item, index) => (
          <Tooltip key={`snav${index}`} title={item.title} placement="right">
            <Link to={item.link}>
              <ListItem disableGutters>
                <ListItemButton sx={{ padding: "5px 0 5px 7px" }}>
                  <ListItemIcon
                    sx={{ margin: "0 auto", color: "text.primary" }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </List>
      <Divider />
      <List>
        <Tooltip title="Mode escuro" placement="right">
          <ListItem disableGutters>
            <ListItemButton
              sx={{ padding: "5px 0 5px 7px" }}
              onClick={() => {
                theme?.change();
              }}
            >
              <ListItemIcon sx={{ margin: "0 auto", color: "text.primary" }}>
                {theme?.mode === "light" && <ToggleOffOutlinedIcon />}
                {theme?.mode === "dark" && <ToggleOnOutlinedIcon />}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "background.default",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "background.default",
          backgroundImage: "none",
          boxShadow: "none",
          paddingTop: ".5em",
        }}
      >
        <Toolbar>{toolbar}</Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
        <Snackbar />
        <Modal />
      </Box>
    </Box>
  );
}

export default Admin;
