import TimeAgo from "javascript-time-ago";
import pt from "javascript-time-ago/locale/pt";

TimeAgo.addDefaultLocale(pt);
const timeAgo = new TimeAgo("pt");

export const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const formatDate = (date: string, format = "") => {
  const newDate = new Date(date);
  const day = ("0" + newDate.getDate()).slice(-2);
  const month = ("0" + newDate.getMonth()).slice(-2);
  const year = newDate.getFullYear();
  const minutes = ("0" + newDate.getMinutes()).slice(-2);
  const hours = ("0" + newDate.getHours()).slice(-2);

  if (format) {
    format = format.replace("D", day.toString());
    format = format.replace("M", month.toString());
    format = format.replace("Y", year.toString());

    format = format.replace("H", hours.toString());
    format = format.replace("i", minutes.toString());
    return format;
  }

  return `${day} de ${
    monthNames[newDate.getMonth()]
  } de ${year} às ${hours}:${minutes}`;
};

export const formatTimeAgo = (date: string) => {
  const newDate = new Date(date);
  return timeAgo.format(newDate);
};

export const formatHistoryText = (text: string) => {
  if (text.includes("Criou uma nova pasta")) {
    return "Criou uma nova pasta";
  }

  if (text.includes("Apagou um arquivo/pasta")) {
    return "Apagou um arquivo/pasta";
  }

  if (text.includes("Enviou um arquivo")) {
    return "Enviou um arquivo";
  }

  if (text.includes("Baixou um arquivo")) {
    return "Baixou um arquivo";
  }

  if (text.includes("Realizou um download do runrun.it")) {
    return "Fez download de documento do RR";
  }

  if (text.includes("Realizou um download zipado do runrun.it")) {
    return "Fez download zipado do RR";
  }

  if (text.includes("Realizou um download zipado do runrun.it")) {
    return "Fez download zipado do RR";
  }

  return text;
};

export const humanFileSize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const phoneFormatting = (number: any) => {
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
  const str = number.replace(/[^0-9]/g, "").slice(0, 11);

  const result = str.replace(regex, "($1)$2-$3");

  return result;
};
