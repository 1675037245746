import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ViewContent from "../../../components/ViewContent";

import { Alert, AlertTitle, Chip, Stack, Link, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { fetchData } from "../../../fetchData";
import { formatDate } from "../../../utils";

const data = [
  {
    id: 1,
    title: "Comunicado: Atendimento 18/07, treinamento",
    status: "Enviado",
    created_at: "2023-07-12 10:00",
  },
];

const Messaging = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const columns = [
    {
      field: "title",
      headerName: "Comunicado",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate(`/messaging/${params.id}`);
            }}
            sx={{
              overflow: "hidden",
              justifyContent: "left",
              cursor: "pointer",
            }}
          >
            {params.row.title}
          </Link>
        );
      },
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Situação",
      width: 100,
    },
    { field: "created_at", headerName: "Criado/Enviado", width: 200 },
  ];

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Ops!</AlertTitle>
        Um erro inesperado ocorreu. Tente novamente mais tarde.
      </Alert>
    );
  }

  return (
    <ViewContent
      title="Comunicados"
      icon={<CampaignOutlinedIcon sx={{ mr: 2 }} />}
    >
      <DataGrid rows={data} columns={columns} autoHeight />
    </ViewContent>
  );
};

export default Messaging;
