import AuthProvider from "./providers/AuthProvider";
import ThemesProvider from "./providers/ThemeProvider";
import SnackbarProvider from "./providers/SnackbarProvider";
import ModalProvider from "./providers/ModalProvider";
import Routes from "./routes/index";
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    console.log("renderizou!");
  });

  return (
    <AuthProvider>
      <ThemesProvider>
        <SnackbarProvider>
          <ModalProvider>
            <Routes />
          </ModalProvider>
        </SnackbarProvider>
      </ThemesProvider>
    </AuthProvider>
  );
}
