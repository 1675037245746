import { useState } from "react";

import {
  Alert,
  MenuItem,
  Typography,
  Drawer,
  Box,
  Stack,
  IconButton,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";

import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  title: string;
  id?: string;
  onOpen?: () => void;
}

const segments = [
  {
    label: "Todos os clientes",
    value: "all",
  },
];

const channels = [
  {
    label: "Todos",
    value: "todos",
  },
  {
    label: "E-mail",
    value: "email",
  },
  {
    label: "SMS",
    value: "sms",
  },
];

const MessagingDrawer = ({ onOpen, title, id = "" }: Props) => {
  const [state, setState] = useState(false);

  return (
    <>
      {!id && (
        <MenuItem
          onClick={() => {
            if (onOpen) onOpen();
            setState(true);
          }}
        >
          <CampaignIcon sx={{ marginRight: ".5em" }} />
          <Typography textAlign="center">Comunicado</Typography>
        </MenuItem>
      )}
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2} justifyContent="space-between">
            <Stack spacing={2} direction="column">
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flex: 1 }}
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    sx={{ width: 170, wordWrap: "break-word" }}
                  >
                    {title}
                  </Typography>
                </Stack>
                <IconButton
                  onClick={() => {
                    setState(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider sx={{ my: 2 }} />
              <TextField label="Título" size="small" />
              <Divider sx={{ my: 2 }} />
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="E-mail"
                />
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="SMS"
                />
              </Stack>
              <TextField label="Segmento" select size="small">
                {segments.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Divider sx={{ my: 2 }} />
              <TextField label="Assunto" size="small" />
              <TextField label="Conteúdo" multiline rows={10} size="small" />
              <TextField label="Texto SMS" multiline rows={5} size="small" />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button fullWidth variant="contained">
                Salvar
              </Button>
              <Button fullWidth variant="contained" color="success">
                Enviar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default MessagingDrawer;
