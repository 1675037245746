import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { fetchData } from "../../../../fetchData";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";

import { npsOptions } from "../../../../utils/labels";

type FormValues = {
  question: string;
};

const AddNpsQuestion = ({
  values,
  handleDataSave,
}: {
  values: any;
  handleDataSave: any;
}) => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  async function onSubmit(data: any) {
    const questions = values.questions;
    setProgress(true);

    questions.push(data.question);

    if (await handleDataSave({ ...values, questions })) {
      snackbar?.open("Questão adicionada com sucesso!", "success");
    } else {
      snackbar?.open(
        "Ops! No momento não foi possível realizar a inclusão.",
        "error",
      );
    }

    modal?.close();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("question", {
          required: "Formule a questão desejada.",
        })}
        multiline
        rows={5}
        label="Questão"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        disabled={progress}
        error={errors?.question ? true : false}
      />
      {errors?.question && (
        <Alert severity="error">{errors.question.message?.toString()}</Alert>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={modal?.close}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Criar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default AddNpsQuestion;
