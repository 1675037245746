import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { fetchData } from "../../../../fetchData";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useModal } from "../../../../contexts/ModalContext";
import { organizationsProfileLabels } from "../../../../utils/labels";

type FormValues = {
  name: string;
  reference: number;
  document: string;
  profile: string;
};

const AddOrganization = () => {
  const modal = useModal();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      profile: "",
    },
  });

  const showError = () => {
    snackbar?.open(
      "Ops! No momento não foi possível criar a organização.",
      "error",
    );
  };

  const handleReferenceValidation = async (reference: number) => {
    try {
      const check = await fetchData(`/organizations?reference=${reference}`, {
        method: "GET",
      });

      if (check.count) {
        snackbar?.open(
          "O número de referência escolhido já existe no sistema.",
          "warning",
        );
        return true;
      }
    } catch (error) {
      showError();
      return true;
    }
    return false;
  };

  async function onSubmit(data: any) {
    setProgress(true);

    const validateRef = await handleReferenceValidation(data.reference);

    if (validateRef) {
      setProgress(false);
      return;
    }

    data.reference = Number(data.reference);

    try {
      const org = await fetchData(`/organizations`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      snackbar?.open("Organização criada com sucesso!", "success");

      navigate(`/organizations/${org._id}`);
      modal?.close();
    } catch (error) {
      showError();
    }

    setProgress(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("name", {
          required: "Informe a Razão Social da organização.",
        })}
        label="Razão Social"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.name ? true : false}
      />
      {errors?.name && (
        <Alert severity="error">{errors.name.message?.toString()}</Alert>
      )}

      <TextField
        {...register("reference", {
          required: "Informe o código/referência da organização.",
        })}
        label="Referência/Código"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        type="number"
        error={errors?.reference ? true : false}
      />
      {errors?.reference && (
        <Alert severity="error">{errors.reference.message?.toString()}</Alert>
      )}

      <TextField
        {...register("document", {
          required: "Informe o CNPJ da organização.",
        })}
        label="CNPJ"
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        error={errors?.document ? true : false}
      />
      {errors?.document && (
        <Alert severity="error">{errors.document.message?.toString()}</Alert>
      )}

      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Carteira"
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            error={errors?.profile ? true : false}
          >
            {organizationsProfileLabels.map((label: string) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
        control={control}
        name="profile"
        rules={{ required: "Selecione a carteira da organização." }}
      />
      {errors?.profile && (
        <Alert severity="error">{errors.profile.message?.toString()}</Alert>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={modal?.close}
        >
          Cancelar
        </Button>
        <Button size="small" variant="contained" color="primary" type="submit">
          {!progress && "Criar"}
          {progress && <CircularProgress size={12} sx={{ color: "#FFF" }} />}
        </Button>
      </Stack>
    </form>
  );
};

export default AddOrganization;
